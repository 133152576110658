import { autoinject } from "aurelia-framework";
import { UserDetailsClient } from "./application/gql/userDetails.tsgql";

@autoinject
export class CurrentUser {
    private static _loaded: boolean = false;

    private static _isLoggedIn: boolean = false;
    public get isLoggedIn() { return CurrentUser._isLoggedIn; }

    private static _name: string = "name";
    public get name() { return CurrentUser._name; }

    private static _email: string;
    public get email() { return CurrentUser._email; }

    private static _id: string;
    public get id() { return CurrentUser._id; }

    private static _isAdmin: boolean = false;
    public get isAdmin() { return CurrentUser._isAdmin; }

    private static _isFullyAuthenticated: boolean = false;
    public get isFullyAuthenticated() { return CurrentUser._isFullyAuthenticated; }

    private static _applicationIds: string[] = [];
    public get applicationIds() { return CurrentUser._applicationIds; }

    private static _expired: boolean = false;
    public get expired() { return CurrentUser._expired; }

    constructor(private userDetailsClient: UserDetailsClient) { }

    /** Call this before attempting to get user details.
     *  If you think data may have changed call ReloadUser()
     * */
    public async LoadUser() {
        if (!CurrentUser._loaded) {
            await this.ReloadUser();
        }
    }

    /** Call this if you think user details may have changed
     * 
        This forces a call to the api 
     */
    public async ReloadUser() {
        var result = await this.userDetailsClient.userDetails_userDetails();

        if (result.me) {
            CurrentUser._loaded = true;
            CurrentUser._isLoggedIn = result.me.isAuthenticated;
            CurrentUser._name = result.me.name;
            CurrentUser._email = result.me.emailAddress;
            CurrentUser._id = result.me.id;
            CurrentUser._isAdmin = result.me.isAdmin;
            CurrentUser._isFullyAuthenticated = result.me.isFullyAuthenticated;
            CurrentUser._applicationIds = result.me.applicationIds;
            CurrentUser._expired = result.me.expired;
        }
    }
}

export class Account {
    public name: string = "";
    public accountId: string = "";
}
