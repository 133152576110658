import { autoinject, BindingEngine } from "aurelia-framework";
import { UpdateClaimApplicationClient, UpdateApplicationRequestInput } from "./application/gql/updateClaimApplication.tsgql";
import { CreateCollectionEntityClient, CreateCollectionEntityRequestInput } from "./application/gql/CreateCollectionEntity.tsgql";
import { RemoveCollectionEntityClient, RemoveCollectionEntityRequestInput } from "./application/gql/RemoveCollectionEntity.tsgql";
import { CountryCodeType } from "./application/gql/createUpdateApplication.tsgql";
import { UpdateClaimApplicationBatchClient, UpdateClaimApplicationBatchRequestInput, UpdateInput } from "./application/gql/updateClaimApplicationBatch.tsgql";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject()
export class UpdateService {

    constructor(
        private bindingEngine: BindingEngine,
        private updateClaimApplication: UpdateClaimApplicationClient,
        private createCollectionEntity: CreateCollectionEntityClient,
        private removeCollectionEntity: RemoveCollectionEntityClient,
        private updateClaimApplicationBatch: UpdateClaimApplicationBatchClient,
        private aggregator: EventAggregator)
    { }

    setup(
        ownerId: string,
        applicationId: string,
        objectPrefix: string,
        object: any) {
        //let array = Object.getOwnPropertyNames(object);
        let array = Object.getOwnPropertyNames(Object.getPrototypeOf(object).__metadata__);

        const subs = [];

        const prototype = Object.getPrototypeOf(object);
        let ignores = prototype.__ignores;
        if (!ignores) {
            ignores = [];
        }

        let updateMembers = prototype.__updateMembers;
        if (!updateMembers) {
            updateMembers = [];
        }

        let updateCollections = prototype.__updateCollections;
        if (!updateCollections) {
            updateCollections = [];
        }

        //console.log('setting up listening to', objectPrefix, object, array);

        for (let i = 0; i < array.length; i++) {
            const prop = array[i];

            if (ignores.indexOf(prop) > -1) {
                continue;
            }
            else if (updateMembers.indexOf(prop) > -1) {
                const innerObj = object[prop];
                const innerObjPrefix = objectPrefix + prop + '.';

                if (innerObj) {
                    this.setup(
                        ownerId,
                        applicationId,
                        innerObjPrefix,
                        innerObj);
                }
            }
            else if (updateCollections.indexOf(prop) > -1) {

                const fieldName = objectPrefix + prop;

                const collection = object[prop];
                for (let i = 0; i < collection.length; i++) {
                    const collectionItem = collection[i];

                    if (!collectionItem.id) {
                        console.warn('Collection Item has no Id. Autosave will not work on this item', collectionItem);
                    }

                    const collectionItemPrefix = objectPrefix + prop + '[' + collectionItem.id + ']' + '.';
                    //console.log('listening to collection item', collectionItem, collectionItemPrefix);

                    this.setup(
                        ownerId,
                        applicationId,
                        collectionItemPrefix,
                        collectionItem);
                }

                const sub = this.bindingEngine
                    .collectionObserver(object[prop])
                    .subscribe((updates) => {

                        for (let i = 0; i < updates.length; i++) {

                            const update = updates[i];

                            for (let i = 0; i < update.addedCount; i++) {
                                const newValue = object[prop][update.index + i];

                                if (newValue) {
                                    this.addCollectionValue(applicationId, ownerId, fieldName, newValue);
                                }
                            }

                            const removedValues = update.removed;

                            if (removedValues) {
                                //console.log('removing these', removedValues);
                                for (let i = 0; i < removedValues.length; i++) {
                                    const removedValue = removedValues[i];
                                    this.removeCollectionValue(applicationId, ownerId, fieldName, removedValue);
                                }
                            }
                        }
                        
                    });

                subs.push(sub);
            }
            else {

                const fieldName = objectPrefix + prop;
                const sub = this.bindingEngine
                    .propertyObserver(object, prop)
                    .subscribe(this.updateFieldFn(applicationId, ownerId, fieldName));

                subs.push(sub);
            }
        }
    }

    addCollectionValue(applicationId, ownerId, fieldName, newValue) {
        const req = new CreateCollectionEntityRequestInput();
        req.id = applicationId;
        req.ownerId = ownerId;
        req.fieldName = fieldName;
        req.jsonEntity = JSON.stringify(newValue);

        //console.log('creating entity', req);

        const context = this;

        this.createCollectionEntity.createCollectionEntity_createCollectionEntity(req)
            .then(function (result) {
                if (result.createCollectionEntity.success) {
                    newValue.id = result.createCollectionEntity.id;

                    if (!newValue.id) {
                        console.warn('Collection Item has no Id. Autosave will not work on this item', newValue.id);
                    }

                    //listen to the new item
                    context.setup(
                        ownerId,
                        applicationId,
                        req.fieldName + '[' + newValue.id + ']' + '.',
                        newValue);
                }
                else if (result.createCollectionEntity.isLocked) {
                    context.aggregator.publish('entity-locked');
                } else {
                    throw new Error('Failed to add collection value');
                }
            });
    }

    removeCollectionValue(applicationId, ownerId, fieldName, removedValue) {
        const req = new RemoveCollectionEntityRequestInput();
        req.id = applicationId;
        req.ownerId = ownerId;
        req.fieldName = fieldName;
        req.collectionEntityId = removedValue.id;

        this.removeCollectionEntity.removeCollectionEntity_removeCollectionEntity(req)
            .then((res) => {
                if (res.removeCollectionEntity.success) {
                    this.removedEntities.push(req.fieldName + '[' + req.collectionEntityId + ']');
                }
                else if (res.removeCollectionEntity.isLocked) {
                    this.aggregator.publish('entity-locked');
                }
                else {
                    throw new Error('Failed to remove collection value');
                }
            });
    }

    updateFieldFn(applicationId, ownerId, fieldName: string) {
        return (newValue, oldValue) => {

            // TODO: revisit?
            if (fieldName.endsWith("Other")) {
                fieldName = fieldName.substr(0, fieldName.length - 5);
            }

            const req = new UpdateInput();
            req.fieldName = fieldName;

            const valType = typeof newValue;

            if (!!CountryCodeType[newValue]) {
                req.fieldValueCountryCodeType = newValue;
            }
            else if (valType === 'string') {
                req.fieldValueString = newValue;
            }
            else if (valType === 'boolean') {
                req.fieldValueBool = newValue
            }
            else if (newValue instanceof Date) {
                req.fieldValueDatetime = newValue;
            }
            else if (Array.isArray(newValue)) {
              req.fieldValueStringList = newValue;
            }
              
            else {
                console.warn('unknown valType', valType, newValue);
            }

            this.debounce(
                () => {
                    console.log('pushing a save', req, fieldName);
                    this.pushUpdate(applicationId, ownerId, req);
                },
                2000,
                false,
                applicationId + '|' + ownerId + '|' + req.fieldName
            )();
        };
    }

    removedEntities : string[] = [];
    pendingUpdates: UpdateInput[] = [];

    pushUpdate(id: string, ownerId :string, request: UpdateApplicationRequestInput) {
        this.pendingUpdates.push(request);

        this.debounce(
            () => {
                var batchRequest = new UpdateClaimApplicationBatchRequestInput();
                batchRequest.id = id;
                batchRequest.ownerId = ownerId;
                batchRequest.updates = this.pendingUpdates.filter(x => {
                    // don't attempt to update an entity that we've already removed
                    return this.removedEntities.filter(y => x.fieldName.indexOf(y) > -1).length === 0;
                });

                this.sendBatchUpdateRequest(batchRequest);

                this.pendingUpdates = [];
            },
            2500,
            false,
            id + '|' + ownerId)();
    }

  sendBatchUpdateRequest(batchRequest: UpdateClaimApplicationBatchRequestInput, failureCount: number = 0) {
        this.inProgress[batchRequest.id] = true;
        this.updateClaimApplicationBatch.updateClaimApplicationBatch_updateClaimApplicationBatch(
            batchRequest).then(
              (result) => {
                if (result.updateClaimApplicationBatch.isLocked) {
                  this.aggregator.publish('entity-locked');
                }
                if (!result.updateClaimApplicationBatch.success) {
                  this.handleBatchRequestFailure(batchRequest, failureCount);
                }
                this.inProgress[batchRequest.id] = null;
                console.log('result', result);
              },
              (errorResult) =>
              {
                this.handleBatchRequestFailure(batchRequest, failureCount);
              });
    }

    timeouts = {};
    inProgress = {};

    handleBatchRequestFailure(batchRequest: UpdateClaimApplicationBatchRequestInput, failureCount: number) {
        if (failureCount < 5) {
            failureCount++;
            const timeout = (2 ** failureCount) * 50;
            console.warn('Sending batch updates has failed. Retry attempt ' + failureCount + ' in ' + timeout + 'ms');
            setTimeout(() => {
                this.sendBatchUpdateRequest(batchRequest, failureCount);
            }, timeout);
        }
        else {
            console.error('Failed to push update ' + failureCount + ' times. No retries remaining');
            this.aggregator.publish('save-error');
        }
    }

  /* 
   * Based from https://davidwalsh.name/javascript-debounce-function 
   * but keeps a collection of timers based on the key parameter
   */
    debounce(func, wait, immediate, key) {
      var context = this;
      return function () {
          var args = arguments;
          var later = function () {
              context.timeouts[key] = null;
              if (!immediate) func.apply(context, args);
          };
          var callNow = immediate && !context.timeouts[key];
          clearTimeout(context.timeouts[key]);
          context.timeouts[key] = setTimeout(later, wait);
          if (callNow) func.apply(context, args);
      };
    };

    updatesPending(): boolean {
      const timers = Object
        .entries(this.timeouts)
        .filter((k) => k[1] !== null && k[1] !== undefined);

      const inPro = Object
        .entries(this.inProgress)
        .filter((k) => k[1] !== null && k[1] !== undefined);

      return timers.length > 0 || inPro.length > 0;
    }

    awaitUpdates(): Promise<boolean> {
      var context = this;

      if (!context.updatesPending()) {
        return new Promise<boolean>((resolve) => { resolve(true); })
      }
      else {
        return new Promise<boolean>((resolve, reject) => {
          let wait: any;
          var later = function () {
            const updatesPending = context.updatesPending();
            if (!updatesPending) {
              clearTimeout(wait);
              resolve(true)
            }
          }
          wait = setInterval(later, 500);
        });
      }
    }
}

export class Update {

  static UpdateNested(): any {
      return function (target: any, propertyKey: string) {
        if (!target.__updateMembers) {
            target.__updateMembers = []
        }

        target.__updateMembers.push(propertyKey);
    }
  }

  static UpdateNestedCollection(): any {
    return function (target: any, propertyKey: string) {
        if (!target.__updateCollections) {
            target.__updateCollections = []
        }

        target.__updateCollections.push(propertyKey);
    }
  }

  static IgnoreUpdates(): any {
      return function (target: any, propertyKey: string) {
          if (!target.__ignores) {
              target.__ignores = []
          }

          target.__ignores.push(propertyKey);
      }
  }
}
